@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700;900&display=swap');

@import './_variables.scss';
@import './mediaQueries.scss';

* {
  padding: 0;
  margin: 0;
  outline: none;
}

html, body {
  font-family: Roboto,sans-serif;
  font-weight: 300;
  font-size: 18px;
  width: 100%;
  color: $color-black;
  background: #000000;
  box-sizing: border-box;
  position: relative;
}

body {
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}