$color-black: #2a2d33;
$color-dark-blue2: #05111d;
$color-dark-blue: #000c18;
$color-dark: #192031;
$color-white: #ffffff;
$color-light: #f6f6f6;
$color-gray: #9BA0AC;
$color-yellow: #e6af2e;
$color-light-blue: #e6f0fa;
$color-red: #d64649;
$color-footer: #000000;