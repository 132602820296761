@import './_variables.scss';
@import './_mediaQueries.scss';

.flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.container {
    width: 100%;
    max-width: 1140px;
    box-sizing: border-box
}

h2 {
    font-family: Merriweather, Arial, Helvetica, sans-serif;
    font-size: 2.5rem;
    padding: 10px 0px;
    color: $color-yellow;
    margin-bottom: 36px;
}

p{
    font-size: 1rem;
    color: $color-white;
    line-height: 31px;
}

.section-group {
    .parallax {
        background-image: url('../images/background/about.jpg');
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        height: 1000px;

        .curtain {
            background:rgba(1,1,1,0.1);
            height: 100%;
            box-sizing: border-box;
        }
    }
}

.section-header {
    @extend .flex;
    position: fixed;
    z-index: 999;

    &.shadow {
        background: $color-dark-blue;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.20);
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 0px;
        box-sizing: border-box;
        position: relative;

        .logo {
            width: 100%;
            max-width: 180px;
            cursor: pointer;
        }

        .desktop {
            display: block;
            .nav {
                box-sizing: border-box;
                a {
                    font-size: 0.90rem;
                    font-weight: 600;
                    color: $color-white;
                    text-decoration: none;
                    display: inline-block;
                    padding: 10px;
                    cursor: pointer;
                    &:hover {
                        text-decoration: none;
                    }
                    &.active {
                        color: $color-yellow;
                    }
                }
            }
        }

        .mobile {
            display: none;

            .mobile-nav {
                position: absolute;
                top: 73px;
                right: 0;
                background: $color-dark-blue2;
                width: 100%;

                .nav {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    border-bottom: 1px solid $color-dark;

                    a {
                        font-size: 0.90rem;
                        font-weight: 600;
                        color: $color-white;
                        text-decoration: none;
                        display: inline-block;
                        padding: 15px;
                        cursor: pointer;
                        border-top: 1px solid $color-dark;
                        &:hover {
                            text-decoration: none;
                        }
                        &.active {
                            color: $color-yellow;
                        }
                    }
                }
            }
        }
    }

    @include responsive(lg) {
        .header {
            padding: 10px;
        }
    }

    @include responsive(md) {
        .header {
            .desktop {
                display: none;
            }

            .mobile {
                display: block;
                color: $color-white;
                font-size: 1.5rem;
                padding: 10px;
            }
        }
    }
}

.section-hero {
    @extend .flex;
    justify-content: center;
    background-image: url('../images/background/about.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 700px;
    box-sizing: border-box;

    @include responsive(sm) {
        height: 600px;
    }

    .container {
        .hero {
            padding: 80px 10px;

            h1 {
                font-family: Merriweather, Arial, Helvetica, sans-serif;
                font-size: 3rem;
                color: $color-yellow;
                padding: 25px 0px;
            }
        }
    }
}

.section-about {    
    box-sizing: border-box;

    .curtain-about {
        background: #16212c;
        @extend .flex;
        justify-content: center;
        padding: 100px;
        box-sizing: border-box;

        @include responsive(sm) {
            padding: 70px 20px;
        }
    }
}

.section-services {
    @extend .flex;
    justify-content: center;
    background: $color-light;
    padding: 80px 10px;
    box-sizing: border-box;

    h2 {
        color: $color-black;
    }

    .services {
        .list {
            display: flex;
            justify-content: center;

            .service {
                text-align: center;
                flex: 1 1 45%;
                padding: 0px 8px;
                margin-bottom: 20px;
                
                .card {
                    border-radius: 3px;
                    background: $color-white;
                    padding: 40px 15px 20px 15px;
                    height: 100%;
                    box-sizing: border-box;
                    box-shadow:0 5px 15px rgba(0,0,0,0.08);
                    border:0;
                    -webkit-transform: scale(1);
                    -ms-transform: scale(1);
                    transform: scale(1);
                    -webkit-transition: .3s cubic-bezier(.2,.8,.2,1);
                    -o-transition: .3s cubic-bezier(.2,.8,.2,1);
                    transition: .3s cubic-bezier(.2,.8,.2,1);

                    &:hover {
                        -webkit-transform: scale(1.03);
                        -ms-transform: scale(1.03);
                        transform: scale(1.03);
                    }

                    h4 {
                        color: $color-black;
                        padding: 20px 10px; 
                        font-size: 1rem;
                    }
    
                    img {
                        width: 80px;
                        &.dev {
                            width: 155px
                        }
                        &.gateway {
                            width: 66px;
                        }
                    }
    
                    p {
                        font-size: 0.90rem;
                        color: $color-black;
                    }
                }
            }

            @include responsive(lg) {
                flex-wrap: wrap  
            }

            @include responsive(sm) {
                .service {
                    flex: 1 1 100%;
                }
            }
        }
    }
}

.section-projects {
    @extend .flex;
    justify-content: center;
    position: relative;
    padding: 80px 10px;
    background: $color-dark;
    box-sizing: border-box;

    .container {
        z-index: 10;
        .projects {
            h2 {
                color: $color-white;
            }

            .featured {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                margin-bottom: 20px;

                .card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 20%;
                    padding: 20px 10px;
                    border-radius: 6px;
                    // background: rgba(255, 255, 255, 0.15);

                    .img-circle {
                        height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 10px;

                        img {
                            width: 100%;
                        }
                    }

                    &:first-child {
                        img {
                            width: 140%;
                        }
                    }

                    h4 {
                        color: $color-white;
                        display: none;
                        font-weight: 400;
                        font-size: 1rem;
                    }
                }

                @include responsive(md) {
                    .card {
                        width: 30%;
                    }
                }

                @include responsive(sm) {
                    flex-direction: column;

                    .card {
                        width: 50%;
                        min-width: 250px;
                        margin-bottom: 20px;
                    }
                }
            }

            .platforms {
                margin-top: 50px;

                h3 {
                    color: $color-light;
                    margin-bottom: 30px;
                }

                .list {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex-wrap: wrap;

                    .card {
                        display: flex;
                        flex-direction: column;
                        margin-right: 50px;
                        align-items: center;
                        justify-content: center;
                        padding: 20px 10px;
                        border-radius: 6px;
                        height: 150px;
                        width: 200px;
                        margin-bottom: 30px;
                        text-align: center;

                        .img-circle {
                            background: $color-yellow;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 80px;
                            height: 80px;
                            img {
                                width: 50px;
                            }
                        }

                        h4 {
                            margin-top: 10px;
                            font-size: 0.90rem;
                            font-weight: 300;
                            color: $color-white;
                        }

                        @include responsive(lg) {
                            margin-right: 0px;
                        }

                        @include responsive(sm) {
                            justify-content: flex-start;
                            height: 104px;
                            width: 86px;

                            h4 {
                                font-size: 0.70rem;
                            }

                            .img-circle {
                                width: 50px;
                                height: 50px;

                                img {
                                    width: 31px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .wave {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        background: $color-dark-blue;
        z-index: 5;

        .wave-dark {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 769px;

            .wave-path {
                transform: scale(1.2, 1);
                transform-origin: 50% 100%;
            }

            .wave-path-1{
                fill: rgba(255, 255, 255, 0.05);
            }

            .wave-path-2 {
                fill: rgba(255, 255, 255, 0.02);
            }

            .wave-path-3 {
                fill: rgba(255, 255, 255, 0.02);
            }

            @include responsive(sm) {
                top: 1502px;
            }
        }
    }
}

.section-career {
    @extend .flex;
    justify-content: center;
    padding: 50px 10px;
    background: #f5f5f5;
    background-image: url('../images/background/join.jpg');
    background-repeat: no-repeat;
    background-size: 50% 100%;
    background-position: right;
    height: 400px;
    box-sizing: border-box;

    h2 {
        color: $color-black;
        text-align: left;
    }

    .card {
        text-align: center;
        p {
            color: $color-dark;
            text-align: left;

            a{
                display: inline-block;
                font-weight: 600;
                text-decoration: none;
                color: $color-dark-blue;
                margin-left: 5px;
            }

        }
    }

    @include responsive(md) {
        background-size: cover;
        
        .container {
            display: flex;
            align-items: center;
            justify-content: center;

            .career {
                background: rgba(255, 255, 255, 0.70);
                width: 60%;
                padding: 20px;
                border-radius: 6px;
                box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.20);
    
                h2 {
                    font-size: 1.5rem;
                    margin-bottom: 10px;
                }

                h2, p {
                    text-align: center !important;
                }
            }
        }
    }

    @include responsive(sm) {
        background-position: center;
        .container {
            .career {
                width: 80%;
            }
        }
    }
}

.section-contact {
    @extend .flex;
    justify-content: center;
    background: $color-dark-blue;
    padding: 60px 10px;
    box-sizing: border-box;

    .contact {
        h2 {
            margin-bottom: 0px;
        }

        h3 {
            color: $color-white;
            padding-right: 100px;
            margin-bottom: 50px;
            font-weight: 300;
        }
    }

    .details {
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;

        @include responsive(md) {
            flex-direction: column;
        }

        .form-contact {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            padding: 30px 20px;
            box-sizing: border-box;
            border-radius: 6px;
            background: rgba(255, 255, 255, 0.15);

            .form-group {
                display: block;
                padding: 5px 0px;
                margin-bottom: 10px;
                width: 100%;
                box-sizing: border-box;
                
                .form-label {
                    display: block;
                    color: $color-white;
                    padding: 5px 0px;

                    .required {
                        color: $color-red;
                    }
                }

                .form-control {
                    padding: 12px 16px;
                    width: 100%;
                    box-sizing: border-box;
                    font-size: 1rem;
                    color: $color-black;
                    background: $color-light;
                    border: 0px;
                    border-radius: 3px;
                }
            }

            .form-action-group {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                box-sizing: border-box;

                .btn-submit {
                    background-color: $color-yellow;
                    border: 1px solid $color-dark-blue;
                    color: $color-dark-blue2;
                    border-radius: 3px;
                    min-width: 200px;
                    padding: 10px;
                    font-size: 1rem;

                    &.disabled{
                        background: $color-gray;
                        cursor:not-allowed;
                    }
                }

                @include responsive(sm) {
                    flex-direction: column;

                    .btn-submit {
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }

            .error {
                background: $color-red;
                color: $color-white;
                width: 100%;
                padding: 10px;
                margin-bottom: 10px;
                box-sizing: border-box;
                border-radius: 3px;
            }
        }

        .business-card {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            padding: 10px;
            margin-bottom: 20px;
            box-sizing: border-box;

            h4 {
                color: $color-white;
            }

            p {
                text-align: left;
            }

            .address {
                margin-bottom: 20px;
            }

            .contacts {
                display: flex;
                .col {
                    flex: 1
                }
            }

            .icons {
                font-size: 24px;
                margin-right: 10px;
            }

            .social {
                margin-top: 20px;
                flex: 1;

                a {
                    text-decoration: none;
                    color: $color-white;
                }
            }

            @include responsive(sm) {
                h4, p {
                    text-align: center;
                }

                .contacts {
                    flex-direction: column;
                }

                .social {
                    text-align: center;
                }
            }
        }
    }

    @include responsive(sm) {
        .contact {    
            h3 {
                padding: 0px;
                font-size: 1rem;
            }
        }
    }
}

.section-footer {
    @extend .flex;
    justify-content: center;
    background: $color-footer;
    min-height: 350px;
    box-sizing: border-box;

    .footer {
        display: flex;
        align-items: center;
        justify-content: center;

        .col {
            flex: 1;
            padding: 10px;

            &.brand {
                img {
                    width: 200px;
                }

                p {
                    font-size: 0.90rem;
                    color: $color-gray;
                    margin-top: 10px;
                    line-height: 24px;
                    text-align: justify;
                }

                @include responsive(sm) {
                    p {
                        text-align: center;
                    }
                }
            }

            &.quicklinks {
                nav {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a {
                        color: $color-white;
                        display: block;
                        text-decoration: none;
                        font-size: 0.90rem;
                        padding: 5px;
                    }
                }

                .icons {
                    font-size: 24px;
                    padding: 5px;
                }

                .social {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        @include responsive(sm) {
            flex-direction: column;
            text-align: center;
        }
    }

    small {
        text-align: center;
        width: 100%;
        display: block;
        color: $color-gray;
    }
}

@include responsive(md) {
    h1 {
        text-align: center;
        font-size: 2.5rem !important;
    }

    h2 {
        text-align: center;
        font-size: 2rem;
    }

    h3 {
        text-align: center;
        font-size: 1.2rem;
        font-weight: 400;
    }

    p {
        text-align: center;
        font-size: 0.90rem;
        box-sizing: border-box;

        br {
            display: inline;
        }
    }
}

@include responsive(sm) {
    h1 {
        text-align: center;
        font-size: 2rem !important;
    }

    p {
        text-align: center;
        font-size: 0.85rem;
        box-sizing: border-box;
        line-height: 26px;

        br {
            display: inline;
        }
    }
}